
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Anomaly_Detection from './assets/Anomaly_Detection.png'; // Adjust the path as necessary

function ModelDrawer() {
    const FeatureCard = ({feature}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    padding:'10px',
                    gap:'10px',
                    alignItems:'center'
                }}
            >
                <CheckCircleOutlineIcon sx={{fill:'#9680ed'}}/>
                <Typography fontSize='1.2rem'>{feature}</Typography>
            </Box>
        )
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        paddingTop:'80px',
        paddingBottom:'80px',
        paddingLeft:'60px',
        paddingRight:'60px',
        backgroundColor:'#251566',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        color:'white',
        gap:'35px',
      }}
    >
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                gap:'10px',
            }}
        >
            <Typography maxWidth='500px' paddingBottom='10px' variant='h3' fontSize='2.3rem'>
                Breakthrough time series AI using transformers
            </Typography>
            <FeatureCard feature={'Higher accuracy with less data required'}/>
            <FeatureCard feature={'Fastest time-to-value'}/>
            <FeatureCard feature={'Reliable even in complex and changing environments'}/>
        </Box>
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                overflow:'hidden',
                backgroundColor:'#9680ed',
            }}
        >
            <img style={{maxWidth: '90vw'}} src={Anomaly_Detection} alt="Praxis Platform" />
        </Box>
    </Box>
  );
}

export default ModelDrawer;
