import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Anomaly_Detection from './assets/Anomaly_Detection.png'
import Model_Summary from './assets/Model_Summary.png'

const sections = [
    { id: 'section1', text: 'Item 1', image: Model_Summary },
    { id: 'section2', text: 'Item 2', image: Model_Summary },
    { id: 'section3', text: 'Item 3', image: Model_Summary },
    { id: 'section4', text: 'Item 4', image: Model_Summary }
];

function App() {
    const [activeSection, setActiveSection] = useState(null);
    const textRefs = useRef([]);
    const imageRefs = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 2 - 250;
            console.log(scrollPosition);
            sections.forEach((section, index) => {
                const element = textRefs.current[index];
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const elementTop = rect.top + window.scrollY;
                    const elementBottom = elementTop + rect.height;

                    if (scrollPosition > elementTop && scrollPosition < elementBottom) {
                        setActiveSection(section.id);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="App">
            <div className="left-column">
                {sections.map((section, index) => (
                    <div
                        key={section.id}
                        ref={(el) => textRefs.current[index] = el}
                        className={`text-item ${activeSection === section.id ? 'bold' : ''}`}
                    >
                        {section.text}
                    </div>
                ))}
            </div>
            <div className="right-column">
                {/* {sections.map((section, index) => (
                    <div
                        key={section.id}
                        ref={(el) => imageRefs.current[index] = el}
                        className={`image-item ${activeSection === section.id ? 'visible' : ''}`}
                    >
                        <img src={section.image} alt={section.text} />
                    </div>
                ))} */}
                    <div
                        key={'section1'}
                        ref={(el) => imageRefs.current[0] = el}
                        className={`image-item ${activeSection === 'section1' ? 'visible' : ''}`}
                    >
                        <img src={Anomaly_Detection} alt={'Anomaly Detection'} />
                    </div>
            </div>
        </div>
    );
}

export default App;