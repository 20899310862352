
import { Box, Typography, Button } from "@mui/material";
import praxisLogo from './assets/praxisLogo.png'
import EastIcon from '@mui/icons-material/East';
function Header() {
  return (
    <Box
      sx={{
        position:'fixed',
        top:'0',
        width:'100%',
        display:'flex',
        alignItems:'center',
        backgroundColor:'#F6F5F5',
        justifyContent:'space-between',
        boxShadow:1,
      }}
    >
      <img style={{paddingLeft:'20px', maxWidth:'170px', filter:'invert(100%'}} src={praxisLogo} alt="logo" />
      <Button
        sx={{
          margin:'20px',
          padding:'10px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          color:'white',
          borderRadius:'10px',
          backgroundColor:'#251566',
          textTransform:'none',
          '&:hover':{
            boxShadow:2,
            backgroundColor:'#251566',
          },
          marginRight:'30px',
          gap:'10px'
        }}
        component="a"
        href={`mailto:rliyanage@praxis-tech.ai`}
        variant="contained"
        color="primary"
      >
        <Typography fontSize='1.1rem'>Book my demo</Typography>
        <EastIcon/>
      </Button>
    </Box>
  );
}

export default Header;
