
import { Box, Typography, Slide, useTheme, useMediaQuery } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Optimization_UseCase from './assets/Optimization_UseCase.png'; // Adjust the path as necessary
import * as React from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import SearchIcon from '@mui/icons-material/Search';
import FunctionsIcon from '@mui/icons-material/Functions';
import ErrorIcon from '@mui/icons-material/Error';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

function Features() {
  const containerRef = React.useRef(null);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const FeatureCard = ({icon, feature, description}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    padding:'10px',
                    gap:'20px',
                }}
            >
                <Box
                  sx={{
                    paddingTop:'5px'
                  }}
                >
                  {icon}
                </Box>
                <Box>
                  <Typography variant='h6'>{feature}</Typography>
                  <Typography>{description}</Typography>
                </Box>
            </Box>
        )
    }

  return (
    <Box
      sx={{
        paddingTop:'80px',
        paddingBottom:'80px',
        paddingLeft:'60px',
        paddingRight:'60px',
        backgroundColor:'#291773',
        justifyContent:'center',
        display:'flex',
        flexDirection:isSmallScreen ? 'column' : 'row',
        color:'white',
        gap:'50px',
        alignItems:'center'
      }}
    >
          {
            isSmallScreen &&
            <Box
              sx={{
                padding:'10px',
                backgroundColor:'#d5d4ef',
                alignItems:'center',
                justifyContent:'center',
                display:'flex',
                width:'120px',
                borderRadius:'8px',
                color:'rgba(30,30,30,1)',
                border:'1px solid #d5d4ef',
                marginBottom:'20px',
                marginLeft:'20px'
              }}
            >
              <Typography maxWidth='500px' variant='h3' fontSize='1.5rem'>
                  Use Cases
              </Typography>
            </Box>
          }
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                overflow:'hidden',
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#261568',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: 18,
                    textAlign: 'center',
                    backgroundImage: `
                        linear-gradient(#3C2D82 1px, transparent 1px),
                        linear-gradient(90deg, #3C2D82 1px, transparent 1px)
                    `,
                    backgroundSize: '100px 100px', // Adjust size as needed,
                    padding:isSmallScreen ? '20px' : '40px',
                    paddingTop:isSmallScreen ? '30px' : '60px',
                    paddingBottom:isSmallScreen ? '30px' : '60px',
                    borderRadius:'15px',
                    border:'1px solid #3C2D82',
                    maxWidth:isSmallScreen ? '90vw' : '50vw',
                    gap:'20px',
                    overflow:'hidden'
                }}
            >
              <Slide direction={'left'} in={true} container={containerRef.current}>
                <img style={{maxWidth:isSmallScreen ? '85vw' : '40vw'}} src={Optimization_UseCase} alt="Praxis Optimization" />
                {/* <img style={{maxWidth:'550px'}} src={Optimization_UseCase} alt="My Example" /> */}
                {/* <img style={{maxWidth:'550px'}} src={Optimization_UseCase} alt="My Example" /> */}
              </Slide>
            </Box>
        </Box>
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                gap:'10px',
                width: !isSmallScreen ? '50vw' : '90vw',
            }}
        >
          {
            !isSmallScreen &&
            <Box
              sx={{
                padding:'10px',
                backgroundColor:'#d5d4ef',
                alignItems:'center',
                justifyContent:'center',
                display:'flex',
                width:'120px',
                borderRadius:'8px',
                color:'rgba(30,30,30,1)',
                border:'1px solid #d5d4ef',
                marginBottom:'20px',
                marginLeft:'20px'
              }}
            >
              <Typography maxWidth={!isSmallScreen ? '50vw' : '90vw'} variant='h3' fontSize='1.5rem'>
                  Use Cases
              </Typography>
            </Box>
          }
            <FeatureCard icon={<SearchIcon/>} feature={'Root Cause Analysis'} description={'Identify unknown issues in your manufacturing environment after an anomaly has occurred'}/>
            <FeatureCard icon={<FunctionsIcon/>} feature={'Optimization'} description={'Find optimal values for controls to achieve a target value'}/>
            <FeatureCard icon={<ErrorIcon/>} feature={'Anomaly Detection'} description={'Analyze time series data to find outliers. Configure alerts and dashboards to monitor performance using the trained model.'}/>
            <FeatureCard icon={<PrecisionManufacturingIcon/>} feature={'New Product Introduction'} description={'Identify ideal operating parameters for new products.'}/>
            <FeatureCard icon={<ProductionQuantityLimitsIcon/>} feature={'Predictive Maintenance'} description={'Monitor equipment performance real time and build models to act before a problem occurs.'}/>
        </Box>
    </Box>
  );
}

export default Features;
