
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Anomaly_Detection from './assets/Anomaly_Detection.png'; // Adjust the path as necessary
import osiPiLogo from './assets/osiPiLogo.png';
import honeywellLogo from './assets/honeywellLogo.png';
import geLogo from './assets/geLogo.png';
import rockwellAutomationLogo from './assets/rockwellAutomationLogo.png';
import siemensLogo from './assets/siemensLogo.png';

// #251566
function HistoriansDrawer() {
    const FeatureCard = ({feature}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    padding:'10px',
                    gap:'10px',
                    alignItems:'center'
                }}
            >
                <CheckCircleOutlineIcon sx={{fill:'#9680ed'}}/>
                <Typography>{feature}</Typography>
            </Box>
        )
    }

  return (
    <Box
      sx={{
        paddingTop:'80px',
        paddingBottom:'80px',
        paddingLeft:'60px',
        paddingRight:'60px',
        backgroundColor:'#F6F5F5',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        flexDirection:'column',
        color:'black',
        gap:'35px',
      }}
    >
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                gap:'10px',
            }}
        >
            <Typography paddingBottom='10px' variant='h3' fontSize='1.8rem'>
                Instantly connect to and get insights from industry standard historians
            </Typography>
        </Box>
        <Box
            sx={{
                display:'flex',
                flexDirection:'row',
                gap:'40px',
                alignItems:'center',
                flexWrap:'wrap'
            }}
        >
            <img style={{maxWidth:'200px', maxHeight:'100px'}} src={geLogo} alt="GE" />
            <img style={{maxWidth:'200px', maxHeight:'100px'}} src={rockwellAutomationLogo} alt="Rockwell Automation" />
            <img style={{maxWidth:'200px', maxHeight:'200px'}} src={siemensLogo} alt="Siemens" />
            <img style={{maxWidth:'200px', maxHeight:'200px'}} src={honeywellLogo} alt="Honeywell" />
            <img style={{maxWidth:'250px', maxHeight:'200px'}} src={osiPiLogo} alt="OSI PI" />
        </Box>
    </Box>
  );
}

export default HistoriansDrawer;
